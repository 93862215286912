// import Head from 'next/head'
// import { useEffect, useState, useRef } from 'react'
// import Link from 'next/link'
// import Image from 'next/image';

// const features = [
//   { title: "AI-Powered Journaling", description: "Enhance your writing with intelligent suggestions." },
//   { title: "Privacy Control", description: "Share your journals to the world or keep them private. Ease your privacy concern." },
//   { title: "Research Assistant", description: "Have a intelligent assistant to keep your research always stand by our side." },
//   { title: "Clean Interface", description: "Simple template for everyone, to manage the focus of writing and reading." },
//   { title: "Closed-loop", description: "Invite others to share your private journals to a circle." },
//   { title: "Freedom of writing", description: "Support the culture of writing. This culture is good, and this is how we can accomodate the newest generation, with the help of AI." },
//   { title: "Versatile Use-case", description: "This platform can be used for professionals writing to just personal used." },
//   { title: "Comment System", description: "Comments in each journal created to have the feedback cycle from the community" },
//   { title: "Agile Development", description: "We try to accommodate the feedback from the users time to time. We make sure the platform can accomodate your writing/ journaling needs." }
// ];

// const LogoSlider = () => {
//   const logos = [
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//     '/spectator.png',
//   ];

//   return (
//     <div className="overflow-hidden bg-gray-50 py-8">
//       <div className="flex animate-scroll">
//         {logos.concat(logos).map((logo, index) => (
//           <div key={index} className="flex-shrink-0 mx-8">
//             <Image src={logo} alt={`Logo ${index + 1}`} width={100} height={50} objectFit="contain" />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const Testimonial = ({ image, name, role, quote }) => (
//   <div className="bg-white rounded-lg shadow-md p-6">
//     <div className="flex items-center mb-4">
//       <Image src={image} alt={name} width={60} height={60} className="rounded-full" />
//       <div className="ml-4">
//         <h4 className="font-semibold text-lg">{name}</h4>
//         <p className="text-gray-600">{role}</p>
//       </div>
//     </div>
//     <p className="text-gray-700 italic">"{quote}"</p>
//   </div>
// );

// const TestimonialSlider = () => {
//   const [currentTestimonial, setCurrentTestimonial] = useState(0);
//   const testimonials = [
//     {
//       image: '/spectator-cry.jpg',
//       name: 'John Doe',
//       role: 'Writer',
//       quote: 'Sojot AI has revolutionized my writing process. The AI assistance is incredibly helpful!'
//     },
//     {
//       image: '/spectator-cry.jpg',
//       name: 'Jane Smith',
//       role: 'Researcher',
//       quote: 'The research tools in Sojot AI have saved me countless hours. Its an indispensable platform.'
//     },
//     {
//       image: '/spectator-cry.jpg',
//       name: 'Dr. Michael Johnson',
//       role: 'Professor, XYZ University',
//       quote: 'Sojot AI is a game-changer for academic writing and collaboration. Highly recommended!'
//     },
//   ];

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
//     }, 10000);
//     return () => clearInterval(timer);
//   }, [testimonials.length]);

//   return (
//     <div className="bg-gray-300 py-8">
//       <div className="max-w-4xl mx-auto px-4">
//         <h3 className="text-xl text-gray-700 font-semibold text-center mb-4">What They Say About Us</h3>
//         <Testimonial {...testimonials[currentTestimonial]} />
//       </div>
//     </div>
//   );
// };

// const TypingCard = ({ title, description }) => {
//   const [text, setText] = useState('');
//   const [isTyping, setIsTyping] = useState(true);

//   useEffect(() => {
//     let i = 0;
//     const typingInterval = setInterval(() => {
//       if (i < description.length) {
//         setText(description.slice(0, i + 1));
//         i++;
//       } else {
//         clearInterval(typingInterval);
//         setIsTyping(false);
//         setTimeout(() => {
//           setIsTyping(true);
//           setText('');
//         }, 1500);
//       }
//     }, 150);

//     return () => clearInterval(typingInterval);
//   }, [description, isTyping]);

//   return (
//     <div className="p-6 bg-white rounded-lg h-52 shadow-md transition duration-300 transform hover:scale-105 relative">
//       <h3 className="text-xl font-bold text-gray-800 mb-4 leading-tight">{title}</h3>
//       <div className="absolute inset-x-0 top-20 bottom-0 flex flex-col justify-start items-stretch">
//         {[...Array(5)].map((_, i) => (
//           <div key={i} className="border-t border-gray-200 flex-grow" />
//         ))}
//       </div>
//       <p className="text-gray-600 leading-relaxed h-20 relative z-10">{text}_</p>
//     </div>
//   );
// };

// export default function Home() {
//   useEffect(() => {
//     const sections = document.querySelectorAll('.section');
//     const observerOptions = {
//       root: null,
//       rootMargin: '0px',
//       threshold: 0.5,
//     };

//     const observerCallback = (entries) => {
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           entry.target.scrollIntoView({ behavior: 'smooth' });
//         }
//       });
//     };

//     const observer = new IntersectionObserver(observerCallback, observerOptions);
//     sections.forEach((section) => observer.observe(section));

//     return () => {
//       sections.forEach((section) => observer.unobserve(section));
//     };
//   }, []);

//   return (
//     <>
//       <Head>
//         <title>Sojot.ai - Your New Writing Experience</title>
//         <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap" rel="stylesheet" />
//         <meta name="description" content="Checkout our cool page" key="desc" />
//         <meta property="og:title" content="Social Jot - New experience of writing and journaling." />
//         <meta
//           property="og:description"
//           content="Harnessing the power of AI, Social Jot became a new experience of journaling/ writing. They missed a simple page to read/ write a journal. The Journal can be social or private. Sharing the writing/ journal will help you to become better. People collect and share their ideas, mindset, diary, their best mind to the world."
//         />
//         <meta
//           property="og:image"
//           content="https://sojot.ai/Sojot-logo.png"
//         />
//       </Head>
//       <main className="snap-y snap-mandatory h-screen overflow-y-scroll font-inter">
//         <section className="section snap-start h-screen flex flex-col items-center justify-center text-center py-24 bg-gradient-to-b from-white to-gray-100">
//           <div className="max-w-4xl mx-auto px-4">
            
//             <h1 className="text-7xl font-black text-gray-900 mb-6 leading-tight">
//               <span className="bg-clip-text">Sojot
//               </span>
//             </h1>
//             <p className="text-3xl font-semibold text-gray-700 mb-4 leading-relaxed">
//               Social Jot. A new writing experience.
//             </p>
//             <p className="text-xl text-gray-600 mb-12 leading-relaxed">
//               Unleash your creativity with AI-powered writing or journaling, storytelling, diary and research tools.
//               Create, share, and manage your thoughts effortlessly.
//             </p>
//             <div className="flex justify-center space-x-4">
//               <a href="#features" className="px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
//                 Explore Features
//               </a>
//               <a href="#signup" className="px-8 py-4 bg-gray-200 text-gray-800 text-lg font-semibold rounded-lg shadow-md hover:bg-gray-300 transition duration-300">
//                 Get Started
//               </a>
//             </div>
//           </div>
//         </section>

//         <section id="features" className="section snap-start min-h-screen py-24 bg-gray-50">
//           <div className="max-w-6xl mx-auto text-center px-4">
//             <h2 className="text-5xl font-black text-gray-900 mb-12 leading-tight">Why Social Jot?</h2>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//               {features.map((feature, index) => (
//                 <TypingCard key={index} title={feature.title} description={feature.description} />
//               ))}
//             </div>
//           </div>
//         </section>

//         <section id="signup" className="section snap-start h-screen pt-32 pb-10 bg-gradient-to-b from-gray-100 to-white flex flex-col justify-between">
//           <div className="max-w-4xl mx-auto text-center px-4">
//             <h2 className="text-3xl font-semibold text-gray-600 mb-4">We just released.</h2>
//             <h2 className="text-6xl font-black text-gray-900 mb-8">Join the Writing Movement</h2>
//             <p className="text-xl text-gray-600 mb-8 leading-relaxed">
//               Sojot is more than just a journaling platform. Its a community of writers, thinkers, and creators.
//             </p>
//             <Link href="/auth/register">
//               <button className="px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
//                 Start Your Journey
//               </button>
//             </Link>
//             <Link href="https://www.producthunt.com/posts/sojot-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sojot&#0045;ai" target="_blank">
//             <Image src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=472418&theme=light" alt="Sojot - Your New Writing Experience | Product Hunt" style="width: 250px; height: 54px;" width={250} height={54} />
//             </Link>;
//           </div>
//           {/* <LogoSlider /> */}
// {/* 
//           <TestimonialSlider /> */}
//         </section>
//       </main>
//     </>
//   )
// }


import Head from 'next/head'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image';

const features = [
  { title: "AI-Powered Journaling", description: "Enhance your writing with intelligent suggestions." },
  { title: "Privacy Control", description: "Share your journals with the world or keep them private. Ease your privacy concern." },
  { title: "Research Assistant", description: "Have an intelligent assistant to keep your research always stand by your side." },
  { title: "Clean Interface", description: "Simple template for everyone, to manage the focus of writing and reading." },
  { title: "Closed-loop", description: "Invite others to share your private journals to a circle." },
  { title: "Freedom of Writing", description: "Support the culture of writing. This culture is good, and this is how we can accommodate the newest generation, with the help of AI." },
  { title: "Versatile Use-case", description: "This platform can be used for professional writing to personal use." },
  { title: "Comment System", description: "Comments on each journal create a feedback cycle from the community." },
  { title: "Agile Development", description: "We try to accommodate feedback from users over time. We make sure the platform can accommodate your writing/journaling needs." }
];

const TypingCard = ({ title, description }) => {
  const [text, setText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < description.length) {
        setText(description.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
        setTimeout(() => {
          setIsTyping(true);
          setText('');
        }, 1500);
      }
    }, 150);

    return () => clearInterval(typingInterval);
  }, [description, isTyping]);

  return (
    <div className="p-6 bg-white rounded-lg h-52 shadow-md transition duration-300 transform hover:scale-105 relative">
      <h3 className="text-xl font-bold text-gray-800 mb-4 leading-tight">{title}</h3>
      <div className="absolute inset-x-0 top-20 bottom-0 flex flex-col justify-start items-stretch">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="border-t border-gray-200 flex-grow" />
        ))}
      </div>
      <p className="text-gray-600 leading-relaxed h-20 relative z-10">{text}_</p>
    </div>
  );
};

export default function Home() {
  useEffect(() => {
    const sections = document.querySelectorAll('.section');
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.scrollIntoView({ behavior: 'smooth' });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <>
      <Head>
        <title>Sojot.ai - Your New Writing Experience</title>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap" rel="stylesheet" />
        <meta name="description" content="Checkout our cool page" key="desc" />
        <meta property="og:title" content="Social Jot - New experience of writing and journaling." />
        <meta
          property="og:description"
          content="Harnessing the power of AI, Social Jot became a new experience of journaling/ writing. They missed a simple page to read/ write a journal. The Journal can be social or private. Sharing the writing/ journal will help you to become better. People collect and share their ideas, mindset, diary, their best mind to the world."
        />
        <meta
          property="og:image"
          content="https://sojot.ai/Sojot-logo.png"
        />
      </Head>
      <main className="snap-y snap-mandatory h-screen overflow-y-scroll font-inter">
        <section className="section snap-start h-screen flex flex-col items-center justify-center text-center py-24 bg-gradient-to-b from-white to-gray-100">
          <div className="max-w-4xl mx-auto px-4">
            
            <h1 className="text-7xl font-black text-gray-900 mb-6 leading-tight">
              <span className="bg-clip-text">Sojot
              </span>
            </h1>
            
            <p className="text-3xl font-semibold text-gray-700 mb-4 leading-relaxed">
              Social Jot. A new writing experience.
            </p>
            
            <p className="text-xl text-gray-600 mb-10 leading-relaxed">
              Unleash your creativity with AI-powered writing or journaling, storytelling, diary and research tools.
              Create, share, and manage your thoughts effortlessly.
            </p>
            
            {/* <div className="flex justify-center space-x-4">
              <a href="#features" className="px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
                Explore Features
              </a>
              <a href="#signup" className="px-8 py-4 bg-gray-200 text-gray-800 text-lg font-semibold rounded-lg shadow-md hover:bg-gray-300 transition duration-300">
                Get Started
              </a>
            </div> */}
            <div className="mt-2 flex justify-center mb-2">
              <a className='text-center' href="https://www.producthunt.com/posts/sojot-ai?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sojot&#0045;ai" target="_blank">
                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=472418&theme=light" alt="Sojot - Your New Writing Experience | Product Hunt" style={{ width: '250px', height: '54px' }} />
              </a>
            </div>
          </div>
        </section>

        <section id="features" className="section snap-start min-h-screen py-24 bg-gray-50">
          <div className="max-w-6xl mx-auto text-center px-4">
            <h2 className="text-5xl font-black text-gray-900 mb-12 leading-tight">Why Social Jot?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <TypingCard key={index} title={feature.title} description={feature.description} />
              ))}
            </div>
          </div>
        </section>

        <section id="signup" className="section snap-start h-screen pt-32 pb-10 bg-gradient-to-b from-gray-100 to-white flex flex-col justify-between">
          <div className="max-w-4xl mx-auto text-center px-4">
            <h2 className="text-3xl font-semibold text-gray-600 mb-4">We just released.</h2>
            <h2 className="text-6xl font-black text-gray-900 mb-8">Join the Writing Movement</h2>
            <p className="text-xl text-gray-600 mb-8 leading-relaxed">
              Sojot is more than just a journaling platform. It’s a community of writers, thinkers, and creators.
            </p>
            <Link href="/auth/register">
              <button className="px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
                Start Your Journey
              </button>
            </Link>
            <br /> <br />
            <Link href="/pricing" className="px-8 py-4 text-gray-400 text-md">
                I want to see the the pricing plan first.
            </Link>
          </div>
        </section>
      </main>
    </>
  )
}
